import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import dataV from '@jiaminghi/data-view';
// 引入全局css
import './assets/scss/style.scss';
// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';




import Axios from 'axios'
// step2：把axios挂载到vue的原型中，在vue中每个组件都可以使用axios发送请求,
// 不需要每次都 import一下 axios了，直接使用 $axios 即可
Vue.prototype.$axios = Axios
// step3：使每次请求都会带一个 /api 前缀
// Axios.defaults.baseURL = 'http://47.93.59.222:8080/dev-api'// /prod-api 配置api 
// Axios.defaults.baseURL = 'http://192.168.1.139:8080/prod-api'// /prod-api 配置api 
//引入echart
//4.x 引用方式
import * as echarts from 'echarts'
import 'echarts-gl'
import 'echarts-wordcloud';
//5.x 引用方式为按需引用
//希望使用5.x版本的话,需要在package.json中更新版本号,并切换引用方式
//import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

Vue.config.productionTip = false; 

// 全局注册
Vue.component('icon', Icon);
Vue.use(dataV);
Vue.use(ElementUI);

// 比对版本号清除缓存
const VUE_APP_VERSION = require('../package.json').version
const vers = window.localStorage.getItem("screenVersionC");
if (VUE_APP_VERSION != vers) {
  localStorage.clear()
  window.localStorage.setItem("screenVersionC", VUE_APP_VERSION);
  location.reload()
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
